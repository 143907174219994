import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";

function Eco4Funding(props) {
  useDocumentTitle("Eco 4");
  return (
    <div>
      <div class="header-bg">
        <div class="navbar-section">
          <Header />
          <section class="banner-section">
            <div class="container">
              <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12">
                  <h1 class="header-txt" data-aos="fade-up">
                    Apply for ECO4 funding: how the ECO4 scheme is helping
                    families like yours to cope with the ongoing energy crisis
                  </h1>
                  <p class="fontww_7">
                    <span class="color_11">
                      The ECO4 scheme is the final part of an ongoing government
                      initiated scheme to support low-income households and to
                      create more energy efficient homes. As of April 2022 it
                      replaced its previous iteration with the aim of improving
                      the energy efficiency of more UK households. UK Energy
                      Support specialises in Energy Company Obligation (ECO)
                      grants, which are provided at no cost to our customers, so
                      that any energy efficiency upgrade for your home will be
                      fully funded by your energy provider. The ECO4 scheme
                      grant system applications open for September 2022.
                      <span style={{ fontWeight: "bold" }}>
                        The Marigold scheme grant system applications open for
                        September 2022.
                      </span>
                    </span>
                  </p>
                  <p class="fontww_7">
                    <span class="color_11">
                      Remember: energy efficient homes are cheaper to run and
                      lead to a happier quality of life. Comfort. Warmth. Peace
                      of mind. And more cash left over in your wallet to spend
                      on the things you actually enjoy doing.
                    </span>
                  </p>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12">
                  <div class="qa-faq" data-aos="fade-down">
                    <h2 class="fwont_2">
                      <span>
                        <span class="green-dark">
                          It takes just two minutes;
                        </span>{" "}
                        about the same time it takes your kettle to boil enough
                        water for a cup of tea!
                      </span>
                    </h2>
                    <div class="company_btn_wrapper">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="text-decoration-none learn_btn_company_stats"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                      >
                        CHECK ELIGIBILITY
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="qa-faq">
                <div class="row">
                  <div class="col-md-9">
                    <h4>Got a question first? Visit our FAQs page</h4>
                  </div>
                  <div class="col-md-3">
                    <div class="farbtn">
                      <a href="/faqs" class="faq-btn">
                        FAQs
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="step-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="step-box">
                <h2>THREE SIMPLE STEPS:</h2>
                <div class="row">
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon1.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Take 60 seconds of your <br />
                        time to fill out the form
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon2.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        We’ll let you know whether <br />
                        you’re eligible for a grant
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon3.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Our engineers will be in touch to <br />
                        book a survey and installation date
                      </h5>
                    </div>
                  </div>
                </div>
                <span>
                  No more days and nights spent battling the cold. Keep your
                  home nice and cosy and keep those energy bills low.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fon14t_3" data-aos="fade-right">
                  <span>
                    <span class="green-dark">
                      Applying is quick and simple. You’re not forced to commit.{" "}
                    </span>
                    <span class="green-light">
                      AND YOU WON’T NEED TO PAY A PENNY towards the work your
                      home receives
                    </span>
                  </span>
                </h3>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  The best thing about the scheme is that all you need to do is
                  register your interest. Our dedicated team here at Marigold
                  will take care of everything else for you: from checking your
                  eligibility to enrolling you in the scheme, to ensuring all
                  your energy-efficient home improvements are carried out by
                  qualified tradespeople and installers.
                </p>
                <h2 class="fonat_2" data-aos="fade-right">
                  How is the government-backed ECO4 scheme achieving this,{" "}
                  <span class="green-light">and do I qualify?</span>
                </h2>
                <p class="about_us_paragraph mb-0" data-aos="fade-right">
                  The scheme is helping low-income households and vulnerable
                  individuals living in fuel-poor homes (bands F and G) to
                  become more energy efficient. This can be done in a number of
                  ways, for instance: installing roof insulation, repairing and
                  improving central heating systems, installing electric storage
                  heaters and fitting cavity wall insulation{" "}
                  <b>(all FREE of charge)</b>.
                </p>
                <p class="about_us_paragraph mb-0" data-aos="fade-right">
                  The benefit for you is the money you’ll save: month in, month
                  out. Not to mention less pay-day stress and a huge boost to
                  the warmth and comfort levels of your home.
                </p>
                <div class="company_btn_wrapper">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    class="text-decoration-none learn_btn_company_stats"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                  >
                    CHECK ELIGIBILITY
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img src="assets/images/pounds.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", padding: "50px 0" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img src="assets/images/house.jpg" alt="" />
                </figure>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsaont_3" data-aos="fade-right">
                  <span>
                    <span class="green-dark">So, where does </span>
                    <span class="green-light">Marigold</span> feature in all of
                    this?
                  </span>
                </h3>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  We’re the ones who can get you started on your ECO4 grant
                  journey AND see it through to completion right by your side.
                  We will manage your journey from start to finish, working to
                  provide the best options of funding available to create a more
                  comfortable, energy-efficient home for you and your family.
                </p>
                <h2 class="fontss_2" data-aos="fade-right">
                  Okay, so what’s the <span>catch?</span>
                </h2>
                <p class="about_us_paragraph mb-0" data-aos="fade-right">
                  No catch. You just have to meet the government’s criteria as
                  regards the type of benefits you receive. Are you a homeowner
                  or private tenant? Are you in receipt of the qualifying
                  benefits outlined below? There’s a good chance you’ll meet the
                  criteria.
                </p>
                <p class="about_us_paragraph mb-0" data-aos="fade-right">
                  Even if you’re not sure whether or not you qualify, it’s worth
                  your while checking with us! After all, it only takes up a
                  moment of your time and you’ve absolutely nothing to lose.
                </p>
                <div class="company_btn_wrapper">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    class="text-decoration-none learn_btn_company_stats"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                  >
                    CHECK ELIGIBILITY
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="inner-change">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h3 class="fwhatont_3 green-dark">
                <span>
                  <span class="color_25">What’s changed since </span>
                  <span class="green-light">ECO3?</span>
                </span>
              </h3>
              <p class="about_us_paragraph mb-2" data-aos="fade-right">
                There have been some changes in who can qualify under the new
                ECO4 rules, with disability benefits no longer making you
                eligible. Please check the new criteria below (and if in doubt,
                just ask):
              </p>
              <div class="row">
                <div class="col-md-6">
                  <h5 style={{ color: "#0c3e35", fontWeight: 600 }}>
                    ECO4 qualifying benefits:
                  </h5>
                  <ol class="font_7 green-dark">
                    <li>
                      <p class="font_7">
                        Child Tax Credits (CTC) – *some restrictions apply
                      </p>
                    </li>
                    <li>
                      <p class="font_7">
                        Income based Jobseekers Allowance (JSA)
                      </p>
                    </li>
                    <li>
                      <p class="font_7">
                        Income related Employment and Support Allowance (ESA)
                      </p>
                    </li>
                    <li>
                      <p class="font_7">Pension Credit Guarantee Credit</p>
                    </li>
                    <li>
                      <p class="font_7">Universal Credit (UC)</p>
                    </li>
                    <li>
                      <p class="font_7">Warm Home Discount Scheme Rebate</p>
                    </li>
                    <li>
                      <p class="font_7">Working Tax Credit (WTC)</p>
                    </li>
                    <li>
                      <p class="font_7">Housing Benefit</p>
                    </li>
                    <li>
                      <p class="font_7">Pension Credit Saving Credit</p>
                    </li>
                  </ol>
                </div>
                <div class="col-md-6">
                  <h5 style={{ color: "#0c3e35", fontWeight: 600 }}>
                    No longer eligible:
                  </h5>
                  <ol class="font_7 green-dark">
                    <li>
                      <p class="font_7">
                        Armed Forces Independence Payment (AFIP)
                      </p>
                    </li>
                    <li>
                      <p class="font_7">Attendance Allowance</p>
                    </li>
                    <li>
                      <p class="font_7">Carer's Allowance</p>
                    </li>
                    <li>
                      <p class="font_7">Constant Attendance Allowance</p>
                    </li>
                    <li>
                      <p class="font_7">Disability Living Allowance (DLA)</p>
                    </li>
                    <li>
                      <p class="font_7">
                        Industrial Injuries Disablement Benefit (IIDB)
                      </p>
                    </li>
                    <li>
                      <p class="font_7">Personal Independence Payment (PIP)</p>
                    </li>
                    <li>
                      <p class="font_7">Severe Disablement Allowance</p>
                    </li>
                    <li>
                      <p class="font_7">
                        War Pensions Mobility Supplement (WPMS)
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-9">
                  <h2 class="fasont_2">
                    <span>
                      <span class="color_25">
                        *Child Benefit income threshold to qualify to receive
                        ECO 4 fund.
                      </span>
                    </span>
                  </h2>
                  <div class="innermaintable">
                    <table class="zui-table zui-table-rounded">
                      <thead class="">
                        <tr>
                          <th scope="col">Number of children</th>
                          <th scope="col">Single claimant</th>
                          <th scope="col">Member of a couple</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td class="bor-lr">£19,900</td>
                          <td>£27,500</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td class="bor-lr">£24,800</td>
                          <td>£32,300</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td class="bor-lr">£29,600</td>
                          <td>£37,200</td>
                        </tr>
                        <tr>
                          <th scope="row">4+</th>
                          <td class="bor-lr">£34,500</td>
                          <td>£42,000</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="company_btn_wrapper text-center">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      class="text-decoration-none learn_btn_company_stats"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                    >
                      CHECK ELIGIBILITY
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fontas_3" data-aos="fade-right">
                  <span>
                    <span class="green-dark">
                      How do I know the ECO4 scheme is{" "}
                    </span>
                    <span class="green-light">
                      AND YOU WON’T NEED TO PAY legitimate?
                    </span>
                  </span>
                </h3>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  In this day and age, you can never be too careful. Thankfully,
                  the ECO4 scheme is 100% legitimate and government backed. It’s
                  been widely covered by numerous media channels online, but you
                  can read all about it on the UK government’s own website.
                </p>
                <h2 class="fonat_2" data-aos="fade-right">
                  Aims of the ECO4 grant scheme:
                </h2>
                <ul class="font_7">
                  <li>
                    <p class="font_7">
                      Bring properties that fall under bands F or G for energy
                      efficiency up to band D or higher
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Support low-income households struggling to manage with
                      rising fuel costs
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Making the UK more energy efficient and combat climate
                      change
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Bring the scheme to a wider range of people than previous
                      schemes (such as ECO3)
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Get local authorities and energy suppliers directly
                      involved in supporting fuel-poor households and tackling
                      climate change
                    </p>
                  </li>
                </ul>
                <div class="company_btn_wrapper">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    class="text-decoration-none learn_btn_company_stats"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                  >
                    CHECK ELIGIBILITY
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img src="assets/images/uk-parliment.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="red-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>
                What are you waiting for? Pop your details into our simple,
                secure application form and a member of the Marigold customer
                support team will be in touch to let you know what happens next.
              </h5>
            </div>
          </div>
        </div>
      </section>
      {/* <CarouselComponent /> */}
      {/* <section class="our_testimonials center-style float-left w-100">
        <div class="titleprof">
          <div class="container">
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                <h2>Professional Accreditations</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-2">
              <div class="test-left">
                <img src="assets/images/green.webp" alt="" />
                <p>Reg. No NAPI51657</p>
                <p class="texta">
                  *Cavity wall insulation including that installed in party
                  walls, floor insulation, loft insulation (roll insulation &
                  blown insulation), room in roof insulation, condensing
                  boilers, natural gas fired and liquefied petroleum gas fired,
                  heating controls, electric storage heaters
                </p>
              </div>
            </div>
            <div class="col-md-10">
              <div class="row position-relative">
                <div class="carousel-content" data-aos="fade-up">
                  <div class="carousel-outer">
                    <div class="owl-carousel owl-theme">
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/4.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/gas-safe.png" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/8.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/10.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/11.webp" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default Eco4Funding;
