import React from "react";

function Header(props) {
  let pathname = window.location.pathname;
  let subLinks = [
    "/about-us",
    "/faqs",
    "/loft-insulation",
    "/air-source-heat-pumps",
    "/cavity-wall-extraction",
    "/certified-insulation-installers",
    "/flexible-eligibility",
    "/solid-wall-insulation",
    "/ventilation",
    "/qualitymark-protection",
    "/contact-us",
  ];
  return (
    <header className="sticky">
      <div className="container main-box">
        <nav className="navbar navbar-expand-lg navbar-light">
          <a className="navbar-brand" href="/">
            <img
              style={{ maxHeight: "100px" }}
              src="assets/images/logos/marigold-insulation.png"
              alt=""
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className={`nav-item ${pathname === "/" ? "active" : ""}`}>
                <a className="nav-link text-white" href="/">
                  HOME
                </a>
              </li>
              <li
                className={`nav-item ${pathname === "/eco-4" ? "active" : ""}`}
              >
                <a className="nav-link text-white" href="/eco-4">
                  Eco 4 Funding
                </a>
              </li>
              <li
                className={`nav-item ${
                  pathname === "/air-source-heating" ? "active" : ""
                }`}
              >
                <a className="nav-link text-white" href="/air-source-heating">
                  Air Source Heat Pumps
                </a>
              </li>
              <li
                className={`nav-item ${
                  pathname === "/external-wall" ? "active" : ""
                }`}
              >
                <a className="nav-link text-white" href="/external-wall">
                  External Wall Insulation
                </a>
              </li>
              <li
                className={`nav-item ${
                  pathname === "/solar-energy" ? "active" : ""
                }`}
              >
                <a className="nav-link text-white" href="/solar-energy">
                  Solar PV
                </a>
              </li>
              <li
                className={`nav-item ${
                  subLinks.includes(pathname) ? "active" : ""
                } dropdown`}
              >
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  More
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a
                    className="dropdown-item"
                    target="_blank"
                    rel="noreferrer"
                    href="https://drive.google.com/file/d/1CbO_ZWx3suo311-HJd5K0tPhILy27eCi/view?usp=sharing"
                  >
                    EST Form
                  </a>
                  <a
                    className={`dropdown-item ${
                      pathname === "/about-us" ? "active" : ""
                    }`}
                    href="/about-us"
                  >
                    About Us
                  </a>
                  <a
                    className={`dropdown-item ${
                      pathname === "/faqs" ? "active" : ""
                    }`}
                    href="/faqs"
                  >
                    FAQs
                  </a>
                  <a className="dropdown-item" href="javascript:;">
                    Services
                  </a>
                  <a
                    className={`dropdown-item ${
                      pathname === "/loft-insulation" ? "active" : ""
                    }`}
                    href="/loft-insulation"
                  >
                    Loft Insulation
                  </a>
                  <a
                    className={`dropdown-item ${
                      pathname === "/air-source-heat-pumps" ? "active" : ""
                    }`}
                    href="/air-source-heat-pumps"
                  >
                    Air Source Heat Pumps
                  </a>
                  <a
                    className={`dropdown-item ${
                      pathname === "/cavity-wall-extraction" ? "active" : ""
                    }`}
                    href="/cavity-wall-extraction"
                  >
                    Cavity Wall Extraction
                  </a>
                  <a
                    className={`dropdown-item ${
                      pathname === "/certified-insulation-installers"
                        ? "active"
                        : ""
                    }`}
                    href="/certified-insulation-installers"
                  >
                    Certified Insulation Installers
                  </a>
                  {/* <a className="dropdown-item" href="/eco-heating">
                    ECO Heating
                  </a> */}
                  <a
                    className={`dropdown-item ${
                      pathname === "/flexible-eligibility" ? "active" : ""
                    }`}
                    href="/flexible-eligibility"
                  >
                    Flexible Eligibility
                  </a>
                  {/* <a className="dropdown-item" href="/free-heating-grants">
                    Free Heating Grants
                  </a> */}
                  {/* <a
                    className={`dropdown-item ${
                      pathname === "/solar-energy" ? "active" : ""
                    }`}
                    href="/solar-energy"
                  >
                    Solar Energy
                  </a> */}
                  <a
                    className={`dropdown-item ${
                      pathname === "/solid-wall-insulation" ? "active" : ""
                    }`}
                    href="/solid-wall-insulation"
                  >
                    Solid Wall Insulation
                  </a>
                  {/* <a className="dropdown-item" href="/storage-heaters">
                    Storage Heaters
                  </a> */}
                  <a
                    className={`dropdown-item ${
                      pathname === "/ventilation" ? "active" : ""
                    }`}
                    href="/ventilation"
                  >
                    Ventilation
                  </a>
                  <a
                    className={`dropdown-item ${
                      pathname === "/qualitymark-protection" ? "active" : ""
                    }`}
                    href="/qualitymark-protection"
                  >
                    Qualitymark Protection
                  </a>
                  <a
                    className={`dropdown-item ${
                      pathname === "/contact-us" ? "active" : ""
                    }`}
                    href="/contact-us"
                  >
                    Contact Us
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
