import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";

function Flexibility(props) {
  useDocumentTitle("ECO4 Flexible Eligibility");
  return (
    <div>
      <div class="header-bg">
        <div class="navbar-section-we_mv2">
          <Header />
          <section class="banner-section">
            <div class="container">
              <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12">
                  <h1 class="header-txt" data-aos="fade-up">
                    Comfortably heat your house with ECO4 Flexible Eligibility.{" "}
                  </h1>
                  <div class="qabout-faq">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="qaboutbtn">
                          <a href="/faqs" class="qabout-btn">
                            FAQs
                          </a>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <h4>
                          Got a question first?
                          <br />
                          Visit out FAQs page
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-3">
                  <div class="qa-faq" data-aos="fade-down">
                    <h2 class="fdaont_2">
                      How efficient is
                      <br />{" "}
                      <span>
                        <span class="green-light">YOUR</span>
                      </span>{" "}
                      home?
                    </h2>
                    <div class="company_btn_wrapper">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="text-decoration-none learn_btn_company_stats"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                      >
                        CHECK ELIGIBILITY
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section class="step-sec-about mt-3">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="step-box">
                <h2 class="green-dark">THREE SIMPLE STEPS:</h2>
                <div class="row">
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon1.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Take 60 seconds of your <br />
                        time to fill out the form
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon2.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        We’ll let you know whether <br />
                        you’re eligible for a grant
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon3.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Our engineers will be in touch to <br />
                        book a survey and installation date
                      </h5>
                    </div>
                  </div>
                </div>
                <span>
                  No more days and nights spent battling the cold. Keep your
                  home nice and cosy and keep those energy bills low.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="assets/images/flex1_mv2.webp" width="100%" alt="" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>ECO4 Flexible Eligibility - </span>
                    <span class="green-light">
                      the technique that might be advantageous to both your
                      family and your society
                    </span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Regardless of age, financial level, or state of health,
                  everyone has the right to live in a cosy house. Every person
                  seeking to increase the energy efficiency of their home is
                  afforded equal chances thanks to the Local Authority Flexible
                  Eligibility (LA Flex) programme.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  The government has added a new phase to the Energy Company
                  Obligation (ECO4) Scheme called La Flex to make sure it
                  fulfils its promise to combating fuel poverty. It enables
                  participating Councils to expand the aid scheme's qualifying
                  requirements, enabling it to assist more households in their
                  region. This means that the ECO4's Affordable Warmth Scheme
                  will now be more accessible to low-income households with high
                  heating expenses.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Councils must submit a Statement of Intent outlining
                  eligibility requirements in order to use this scheme. Marigold
                  Insulation Ltd. can assist local councils with the
                  implementation of this programme by assisting in the selection
                  of the eligibility standards they desire to utilise to grant
                  residents access to financing. We can also collaborate with
                  regional energy providers to identify homes that might gain
                  from energy-saving upgrades.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>LA Flex's In-fill mechanisms </span>
                    <span class="green-light">
                      could help private tenants too
                    </span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Affordable Warmth grants can now reach even more consumers
                  thanks to LA Flex's requirements, including private tenants,
                  homeowners, and people who don't satisfy the standards of
                  their local authority's Statement of Intent. This enables
                  local councils to designate numerous homes in the region as
                  eligible for assistance, such as in the following situations:
                </p>
                <ul class="font_7">
                  <li>
                    <p class="font_7">
                      Both renters and homeowners experience fuel poverty.{" "}
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      People with compromised immune systems, children, and
                      others are especially exposed to the effects of living in
                      a cold home.
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Occupants are residing in a property next to one that
                      satisfies the requirements; this includes numerous
                      residences in a same building, buildings that are directly
                      adjacent to one another, and homes that are part of the
                      same terrace
                      <br />
                      &nbsp;
                    </p>
                  </li>
                </ul>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  If 66% of a region's homes meet the Affordable Warmth
                  eligibility requirements, all adjacent houses may be eligible
                  for funding for solid wall insulation and other energy-saving
                  measures. To find out more about eligibility, get in touch
                  with a member of the Marigold Insulation Ltd. team right away,
                  or fill out the form below to discover whether you and your
                  household qualify.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="assets/images/flex2_mv2.webp" width="100%" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="red-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>
                Are you eligible for ECO's Affordable Warmth Scheme? Fill in the
                form to find out.
              </h5>
            </div>
          </div>
        </div>
      </section>
      {/* <CarouselComponent /> */}
    </div>
  );
}

export default Flexibility;
