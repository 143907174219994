import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";

function ElectricHeating(props) {
  useDocumentTitle("Air Source Heat Pumps");
  return (
    <div>
      <div class="header-bg">
        <div class="navbar-section">
          <Header />
          <section class="banner-section">
            <div class="container">
              <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12">
                  <h1 class="header-txt" data-aos="fade-up">
                    Grants for free Air Source Heat Pumps.
                  </h1>
                  <p class="fontww_7">
                    <span class="color_11">
                      Find out how the newest, most energy-efficient Air Source
                      Heat Pumps could help you save a tonne of money each month
                      Air Source Heat Pumps.{" "}
                      <span style={{ fontWeight: "bold" }}>
                        And to apply and install them is completely free.{" "}
                      </span>
                    </span>
                  </p>
                  <p class="fontww_7">
                    <span class="color_11">
                      Electricity can be a strong and affordable solution to
                      heat your home for less as energy prices continue to rise
                      and gas gets a bad rap for being bad for the environment!
                      You may be eligible to get free installation of brand-new,
                      top-of-the-line Air Source Heat Pumps in your home . All
                      of this is a component of the government's ECO4 programme,
                      which Marigold may implement for you.
                    </span>
                  </p>

                  <p class="fontww_7">
                    <span class="color_11">
                      Find out right now whether you qualify. It's quick, safe,
                      and only needs a few minutes.
                    </span>
                  </p>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12">
                  <div class="qa-faq" data-aos="fade-down">
                    <h2 class="fwont_2" style={{ lineHeight: "36px" }}>
                      100% FREE{" "}
                      <span>
                        <span class="green-dark">
                          to <br />
                          APPLY and <br />
                          INSTALL
                        </span>
                      </span>
                    </h2>
                    <div class="company_btn_wrapper">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="text-decoration-none learn_btn_company_stats"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                      >
                        CHECK ELIGIBILITY
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="qa-faq">
                <div class="row">
                  <div class="col-md-9">
                    <h4>Got a question first? Visit our FAQs page</h4>
                  </div>
                  <div class="col-md-3">
                    <div class="farbtn">
                      <a href="/faqs" class="faq-btn">
                        FAQs
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="step-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="step-box">
                <h2>THREE SIMPLE STEPS:</h2>
                <div class="row">
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon1.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Take 60 seconds of your <br />
                        time to fill out the form
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon2.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        We’ll let you know whether <br />
                        you’re eligible for a grant
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon3.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Our engineers will be in touch to <br />
                        book a survey and installation date
                      </h5>
                    </div>
                  </div>
                </div>
                <span>
                  No more days and nights spent battling the cold. Keep your
                  home nice and cosy and keep those energy bills low.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3
                  class="font_3"
                  style={{ fontSize: "30px", lineHeight: "28px" }}
                  data-aos="fade-right"
                >
                  <span style={{ fontSize: "30px" }}>
                    <span style={{ color: "#0c3e35" }}>
                      What are the requirements for criteria{" "}
                    </span>
                    <span class="green-light">AND YOU WON'T NEED TO PAY?</span>
                  </span>
                </h3>
                <ul class="font_7">
                  <li>
                    <p class="font_7">
                      Are you a private tenant or a home owner?
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Do you receive benefits based on your income?
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Do you heat your home with electricity?
                    </p>
                  </li>
                </ul>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  You have a strong possibility of being eligible. To find out
                  for sure and to start the process, contact us right away.
                </p>
                <h2
                  class="font_2"
                  style={{
                    fontSize: "18px",
                    lineHeight: "28px",
                    textAlign: "left",
                    color: "#0c3e35",
                  }}
                  data-aos="fade-right"
                >
                  No more sacrificing your comfort to save a few pounds. quid
                </h2>
                <p class="about_us_paragraph mb-0" data-aos="fade-right">
                  These are difficult times as the cost of living continues to
                  rise. increasing energy prices are a major factor in this
                  present day. However, Marigold can assist in reducing some of
                  the tension by getting you fitted with the most recent
                  energy-saving electric storage technology heaters. The
                  straightforward three-step procedure begins the as soon as you
                  enter some basic information into our online contact form
                  form.
                </p>
                <div class="company_btn_wrapper">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    class="text-decoration-none learn_btn_company_stats"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                  >
                    CHECK ELIGIBILITY
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img src="assets/images/thermography.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="freesec">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="free-complete">
                <div class="row">
                  <div class="col-md-5">
                    <div class="fretxt">
                      <h3
                        class="font_3 aos-init aos-animate"
                        style={{
                          fontSize: "30px",
                          fontWeight: 700,
                          lineHeight: "36px",
                          color: "#0c3e35",
                        }}
                        data-aos="fade-right"
                      >
                        <span style={{ fontSize: "30px" }}>
                          <span class="color_25">
                            Apply now and look forward to a home that’s{" "}
                          </span>
                          <span class="green-light">
                            easy and cost-effective{" "}
                          </span>
                        </span>
                        to heat up and keep warm
                      </h3>
                      <div class="bor-bot aos-init" data-aos="fade-left"></div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="freebox elec-hea">
                      <p
                        class="listheader aos-init aos-animate"
                        data-aos="fade-right"
                      >
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`
                                                    #comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;
                        <span>
                          <b>Temperature control:</b> fan-assisted for speed and
                          efficiency
                        </span>
                      </p>
                      <p
                        class="listheader aos-init aos-animate"
                        data-aos="fade-right"
                      >
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`
                                                    #comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;
                        <span>
                          <b>Cool to the touch:</b> no burnt fingers!
                        </span>
                      </p>
                      <p
                        class="listheader aos-init aos-animate"
                        data-aos="fade-right"
                      >
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`
                                                    #comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;
                        <span>
                          <b>Quiet to run:</b> won’t keep you up at night like
                          those noisy models from the past
                        </span>
                      </p>
                      <p class="listheader aos-init" data-aos="fade-right">
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`
                                                    #comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;
                        <span>
                          <b>Space saving:</b> smaller than past models and more
                          energy efficient at the same time
                        </span>
                      </p>
                      <p class="listheader aos-init" data-aos="fade-right">
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`
                                                    #comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;
                        <span>
                          <b>Smart:</b> Works during off-peak hours to save cash
                          for you
                        </span>
                      </p>
                      <p class="listheader aos-init" data-aos="fade-right">
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`
                                                    #comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;
                        <span>
                          <b>Programmable:</b> Set it to your schedule
                        </span>
                      </p>
                      <p class="listheader aos-init" data-aos="fade-right">
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`
                                                    #comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;
                        <span>
                          <b>Efficient:</b> No more throwing heat (and money)
                          down the drain
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", padding: "50px 0" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fossnt_3" data-aos="fade-right">
                  <span>
                    <span class="green-dark">
                      So, how can Air Source Heat Pumps{" "}
                    </span>
                    <span class="green-light">simplify your life?</span>
                  </span>
                </h3>
                <ul class="font_7">
                  <li>
                    <p class="font_7">No need for a main gas supply</p>
                  </li>
                  <li>
                    <p class="font_7">Cheap to operate</p>
                  </li>
                  <li>
                    <p class="font_7">
                      Installation performed for FREE by licenced Marigold
                      engineers{" "}
                    </p>
                  </li>
                  {/* <li>
                    <p class="font_7">
                      Last longer than other available night storage heaters{" "}
                    </p>
                  </li> */}
                  <li>
                    <p class="font_7">
                      They can even automatically adjust to the environment
                      around them - verify whether a window is open!
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Therefore, no heat is lost during inactivity. want it
                    </p>
                  </li>
                  <li>
                    <p class="font_7">More environmentally friendly than gas</p>
                  </li>
                  <li>
                    <p class="font_7">No upkeep necessary</p>
                  </li>
                </ul>

                <h2
                  class="font_2"
                  style={{
                    fontSize: "18px",
                    lineHeight: "28px",
                    textAlign: "left",
                    color: "#0c3e35",
                  }}
                  data-aos="fade-right"
                >
                  How are these any different from the Electric Storage Heaters{" "}
                  <span class="green-light">
                    I've heard are expensive to operate?
                  </span>
                </h2>
                <p class="about_us_paragraph mb-0" data-aos="fade-right">
                  This might be accurate for some older models, but it's no
                  longer the case. this is the situation with the Air Source
                  Heat Pumps. Marigold has installed. Why is that so? Learn
                  right now.
                </p>
                <ul class="font_7">
                  <li>
                    <p class="font_7">
                      They employ the most recent energy-saving, SMART
                      technology. for the greatest possible outcomes.
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Off-peak hours are when Air Source Heat Pumps operate.
                      additionally saving you cash
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      There is no end in sight to the rise in gas prices. This
                      results power is becoming more and more practical,
                      especially since of Air Source Heat Pumps take advantage
                      of off-peak rates of
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Installation is FREE, so there are no up-front costs.{" "}
                    </p>
                  </li>
                </ul>
                <div class="company_btn_wrapper">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    class="text-decoration-none learn_btn_company_stats"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                  >
                    CHECK ELIGIBILITY
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img src="assets/images/air-source-heat-pump-2.webp" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="red-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>
                Why are you holding out? A member of the Marigold customer care
                team will contact you once you provide your information in our
                quick and secure application form to let you know what happens
                next.
              </h5>
            </div>
          </div>
        </div>
      </section>
      {/* <CarouselComponent /> */}
      {/* <section class="our_testimonials center-style float-left w-100">
        <div class="titleprof">
          <div class="container">
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                <h2>Professional Accreditations</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-2">
              <div class="test-left">
                <img src="assets/images/green.webp" alt="" />
                <p>Reg. No NAPI51657</p>
                <p class="texta">
                  *Cavity wall insulation including that installed in party
                  walls, floor insulation, loft insulation (roll insulation &
                  blown insulation), room in roof insulation, condensing
                  boilers, natural gas fired and liquefied petroleum gas fired,
                  heating controls, Air Source Heat Pumps
                </p>
              </div>
            </div>
            <div class="col-md-10">
              <div class="row position-relative">
                <div class="carousel-content" data-aos="fade-up">
                  <div class="carousel-outer">
                    <div class="owl-carousel owl-theme">
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/1.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/2.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/3.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/4.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/5.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/6.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/7.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/8.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/9.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/10.webp" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default ElectricHeating;
