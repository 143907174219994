import React from "react";
import { SocialIcon } from "react-social-icons";

function Footer(props) {
  return (
    <section class="footer-section float-left w-100">
      <div class="container">
        <div class="middle-portion">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 first_padding_left footer-oglo">
              {/* <svg
                preserveAspectRatio="xMidYMid meet"
                data-bbox="-0.004 0.001 898.504 298.558"
                viewBox="-0.004 0.001 898.504 298.558"
                xmlns="http://www.w3.org/2000/svg"
                data-type="color"
                role="img"
                aria-labelledby="svgcid-go2hxmkd6oyh"
              >
                <title id="svgcid-go2hxmkd6oyh">
                  UK Energy Management NE Limited Logo
                </title>
                <g>
                  <g>
                    <path
                      d="M242.8 85.43a27.108 27.108 0 0 0-8.3-8l-54.4-31.4h-.1l-30-17.4a13.135 13.135 0 0 0-1.7-.9l-.1-.1a32.87 32.87 0 0 0-24.1-.3l-.3.1a14.956 14.956 0 0 0-2.2 1.1l-54.4 31.5-30.3 17.4a13.029 13.029 0 0 0-1.5 1l-.1.1a33.123 33.123 0 0 0-12.1 19.4 6.882 6.882 0 0 0-.2 1.3.902.902 0 0 1-.1.5v.2c0 .3-.1.5-.1.8v99.3a7.569 7.569 0 0 0 .1 1.5v.2c.7 7.1 5 15.2 10.7 20.1l.2.1a5.716 5.716 0 0 1 .8.7c.2.1.3.3.5.4.3.2.5.4.7.5l.9.6.3.2 84.6 48.9a13.135 13.135 0 0 0 1.7.9l.1.1a32.876 32.876 0 0 0 24.1.3l.2-.1a25.053 25.053 0 0 0 2.3-1.1l84.6-48.9a13.03 13.03 0 0 0 1.5-1l.1-.1a33.123 33.123 0 0 0 12.1-19.4v-.2c.1-.3.1-.7.2-1 0-.2.1-.5.1-.7v-.8c0-.5.1-1 .1-1.5v-97.7a24.865 24.865 0 0 0-1.5-8 31.816 31.816 0 0 0-4.4-8.6zm-110.4 80.1a33.9 33.9 0 0 1-67.8 0v-52.9a6.65 6.65 0 1 1 13.3 0v52.9a20.5 20.5 0 0 0 41 0v-52.9a6.65 6.65 0 1 1 13.3 0v52.9zm72.1 32.9a7.168 7.168 0 0 1-4.2 1.5 6.535 6.535 0 0 1-5.1-2.4l-29.7-39-8.4 8.8v25.9a6.652 6.652 0 1 1-13.3 0v-81.7a6.65 6.65 0 1 1 13.3 0v36.5l37.4-40a6.63 6.63 0 0 1 10.5 8.1l-30.2 32.5 30.6 40.2a6.654 6.654 0 0 1-.9 9.6z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M267.3 82.23a44.005 44.005 0 0 0-5.5-10.8 34.81 34.81 0 0 0-10.7-10.3l-63.2-36.5h.1l-35.1-20.3a22.56 22.56 0 0 0-2.2-1.1.098.098 0 0 1-.1-.1 41.737 41.737 0 0 0-30.7-.4c-.1 0-.2.1-.4.2a16.812 16.812 0 0 0-3 1.5l-4.9 2.8-58.4 33.6h.1l-35.1 20.2a.098.098 0 0 0-.1.1 22.633 22.633 0 0 0-2 1.3c-.1 0-.1.1-.2.1-7.4 5.4-13.5 15.3-15.3 24.7v.1l-.3 1.5c0 .2-.1.5-.1.7 0 .4-.1.8-.1 1.2a5.702 5.702 0 0 0-.1 1.3v114.4a13.402 13.402 0 0 0 .1 2v.3c1 9.2 6.4 19.3 13.6 25.5l.2.2c.4.3.7.6 1.1.9.2.2.4.3.6.5l.9.6a7.459 7.459 0 0 0 1.2.7c.1.1.3.2.4.3l4.9 2.8 58.4 33.8 35.1 20.2a22.564 22.564 0 0 0 2.2 1.1.098.098 0 0 1 .1.1 41.737 41.737 0 0 0 30.7.4c.1 0 .2-.1.3-.1a16.812 16.812 0 0 0 3-1.5l4.9-2.8 58.3-33.6 35.1-20.2a.098.098 0 0 0 .1-.1 22.627 22.627 0 0 0 2-1.3c.1 0 .1-.1.2-.1 7.5-5.4 13.6-15.3 15.4-24.6a.367.367 0 0 1 .1-.3 6.752 6.752 0 0 0 .2-1.4c0-.3.1-.6.1-.9s.1-.6.1-.9a13.402 13.402 0 0 0 .1-2V92.63a32.647 32.647 0 0 0-2.1-10.4zm-12.7 117.4a12.1 12.1 0 0 1-.1 1.9 1.702 1.702 0 0 1-.1.7v.2c0 .4-.1.6-.1.9-.1.4-.1.9-.2 1.3l-.1.3a38.927 38.927 0 0 1-14.4 23l-.1.1a14.512 14.512 0 0 1-1.9 1.2l-.2.1-75.9 43.8-8.7 5a26.424 26.424 0 0 1-2.9 1.4l-.3.1a37.745 37.745 0 0 1-13.9 2.6 35.919 35.919 0 0 1-14.8-3l-.1-.1c-.8-.4-1.5-.7-2.2-1.1l-84.5-48.8c-.1-.1-.2-.1-.4-.2a12.486 12.486 0 0 1-1.2-.8c-.3-.2-.5-.4-.8-.6s-.5-.4-.7-.5c-.4-.3-.7-.6-1.1-.9l-.2-.2c-6.8-5.9-11.8-15.3-12.7-23.9v-.3a14.084 14.084 0 0 1-.1-2v-98.7a6.15 6.15 0 0 1 .1-1.3c0-.3.1-.7.1-1.1l2.9.2-2.9.1a1.268 1.268 0 0 1 .1-.6c.1-.5.1-1 .2-1.4v-.2a38.927 38.927 0 0 1 14.4-23l.1-.1a14.512 14.512 0 0 1 1.9-1.2l.2-.1 30.2-17.5 45-25.9 9.5-5.5a26.423 26.423 0 0 1 2.9-1.4l.3-.1a38.74 38.74 0 0 1 28.8.4l.2.1a20.139 20.139 0 0 1 2.1 1.1l30.2 17.4 54.4 31.4a33.249 33.249 0 0 1 10.2 9.7 38.378 38.378 0 0 1 5.1 10.1 31.878 31.878 0 0 1 1.9 9.9v97.5z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M347.9 122.13a5.228 5.228 0 0 1-5.2 5.2h-36.4a5.164 5.164 0 0 1-5.2-5.2v-62.4a5.164 5.164 0 0 1 5.2-5.2h36.4a5.2 5.2 0 1 1 0 10.4h-31.2v21h23.8a5.2 5.2 0 1 1 0 10.4h-23.8v20.5h31.2a5.383 5.383 0 0 1 5.2 5.3z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M406.4 101.33v21a5.15 5.15 0 1 1-10.3 0v-21a15.9 15.9 0 1 0-31.8 0v21a5.15 5.15 0 1 1-10.3 0v-21a26.2 26.2 0 0 1 52.4 0z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M464.9 101.33a5.143 5.143 0 0 1-5.2 5.1h-36a15.778 15.778 0 0 0 15 10.7 20.642 20.642 0 0 0 11.7-3.4c2.4-1.6 5.6-.1 6.9 2.4 1.3 2.6-.1 5.3-2.5 6.9-6.9 4.6-12.2 4.5-16.1 4.5a26.2 26.2 0 1 1 0-52.4c12.6 0 26.2 9.2 26.2 26.2zm-41.2-5.3h30.1c-2.2-7.3-8.9-10.6-15.1-10.6a15.957 15.957 0 0 0-15 10.6z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M502.5 80.33a5.228 5.228 0 0 1-5.2 5.2 15.876 15.876 0 0 0-15.9 15.9v21a5.15 5.15 0 1 1-10.3 0v-21a26.225 26.225 0 0 1 26.2-26.2 5.143 5.143 0 0 1 5.2 5.1z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M554.6 127.43a26.236 26.236 0 0 1-49.8 11.5 5.098 5.098 0 0 1 2.4-6.9 5.173 5.173 0 0 1 6.9 2.3 15.82 15.82 0 0 0 14.3 9 15.994 15.994 0 0 0 15.9-15.5v-5.7a26.727 26.727 0 0 1-15.9 5.4 26.2 26.2 0 1 1 26.2-26.2zm-10.3-26.1a15.9 15.9 0 1 0-15.9 15.9 16.138 16.138 0 0 0 15.9-15.9z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M602.8 80.63v51.4a21.54 21.54 0 0 1-40.9 9.4 5.127 5.127 0 0 1 2.4-6.9 5.069 5.069 0 0 1 6.8 2.4 11.28 11.28 0 0 0 21.4-4.9v-7.5a20.427 20.427 0 0 1-10.7 2.9 21.02 21.02 0 0 1-21-20.8v-26.1a5.1 5.1 0 1 1 10.2 0v25.9a10.7 10.7 0 0 0 21.4 0v-25.8a5.201 5.201 0 0 1 10.4 0z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M366.7 218.53l-8.9-43.5-16.4 44a5.251 5.251 0 0 1-5 3.7 5.38 5.38 0 0 1-5-3.7l-14.4-42.5-7.8 41.9a5.17 5.17 0 1 1-10.2-1.7l10.5-62.7a5.26 5.26 0 0 1 10.2-.6l16.9 47.5 17.5-47.6a5.198 5.198 0 0 1 10.1.6l12.7 62.6a5.442 5.442 0 0 1-4.1 6.2c-.3 0-.7.1-1 .1a5.608 5.608 0 0 1-5.1-4.3z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M432.5 197.13v20.4a5.044 5.044 0 0 1-5.2 5.2 5.482 5.482 0 0 1-5-5.2c-4.5 3.8-9.5 5.2-15.4 5.2-14.7 0-26.4-11-26.4-25.7a26.248 26.248 0 0 1 26.4-26.4c14.7 0 25.6 11.8 25.6 26.5zm-10.3 0c0-8.8-6.7-16.2-15.4-16.2a16.46 16.46 0 0 0-16.2 16.2c0 8.8 7.4 15.4 16.2 15.4a15.038 15.038 0 0 0 15.4-15.4z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M491.1 196.73v21a5.15 5.15 0 1 1-10.3 0v-21a15.9 15.9 0 0 0-31.8 0v21a5.15 5.15 0 1 1-10.3 0v-21a26.2 26.2 0 0 1 52.4 0z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M549.4 197.13v20.4a5.044 5.044 0 0 1-5.2 5.2 5.482 5.482 0 0 1-5-5.2c-4.5 3.8-9.5 5.2-15.4 5.2-14.7 0-26.4-11-26.4-25.7a26.248 26.248 0 0 1 26.4-26.4c14.6.1 25.6 11.8 25.6 26.5zm-10.2 0c0-8.8-6.7-16.2-15.4-16.2a16.392 16.392 0 0 0-16.2 16.2c0 8.8 7.4 15.4 16.2 15.4a15.097 15.097 0 0 0 15.4-15.4z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M608 222.93a26.236 26.236 0 0 1-49.8 11.5 5.098 5.098 0 0 1 2.4-6.9 5.173 5.173 0 0 1 6.9 2.3 15.82 15.82 0 0 0 14.3 9 15.994 15.994 0 0 0 15.9-15.5v-5.7a26.727 26.727 0 0 1-15.9 5.4 26.2 26.2 0 1 1 26.2-26.2v26.1zm-10.3-26.2a15.9 15.9 0 1 0-15.9 15.9 16.138 16.138 0 0 0 15.9-15.9z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M746.1 192.03a.902.902 0 0 1 .1.5v25.3a5.228 5.228 0 0 1-5.2 5.2 5.143 5.143 0 0 1-5.1-5.2v-26.2a10.6 10.6 0 0 0-21.2 0v26.3a5.143 5.143 0 0 1-5.1 5.2 5.228 5.228 0 0 1-5.2-5.2v-26.3a10.55 10.55 0 0 0-21.1 0v26.3a5.15 5.15 0 1 1-10.3 0v-26.3a20.976 20.976 0 0 1 21-20.9 20.332 20.332 0 0 1 15.7 7.2 20.64 20.64 0 0 1 15.7-7.2 20.976 20.976 0 0 1 21 20.9c-.2.2-.2.3-.3.4z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M804.7 196.73a5.143 5.143 0 0 1-5.2 5.1h-36a15.778 15.778 0 0 0 15 10.7 20.642 20.642 0 0 0 11.7-3.4c2.4-1.6 5.6-.1 6.9 2.4 1.3 2.6-.1 5.3-2.5 6.9-6.9 4.6-12.2 4.5-16.1 4.5a26.2 26.2 0 1 1 0-52.4c12.7 0 26.2 9.2 26.2 26.2zm-41.2-5.2h30.1c-2.2-7.3-8.9-10.6-15.1-10.6a15.69 15.69 0 0 0-15 10.6z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M665.7 196.73a5.143 5.143 0 0 1-5.2 5.1h-36a15.778 15.778 0 0 0 15 10.7 20.642 20.642 0 0 0 11.7-3.4c2.4-1.6 5.6-.1 6.9 2.4 1.3 2.6-.1 5.3-2.5 6.9-6.9 4.6-12.2 4.5-16.1 4.5a26.2 26.2 0 1 1 0-52.4c12.6 0 26.2 9.2 26.2 26.2zm-41.2-5.2h30.1c-2.2-7.3-8.9-10.6-15.1-10.6a15.597 15.597 0 0 0-15 10.6z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M863.3 196.73v21a5.15 5.15 0 1 1-10.3 0v-21a15.9 15.9 0 0 0-31.8 0v21a5.15 5.15 0 1 1-10.3 0v-21a26.2 26.2 0 0 1 52.4 0z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M898.5 175.73a5.228 5.228 0 0 1-5.2 5.2h-7.4v36.8a5.15 5.15 0 1 1-10.3 0v-36.8H868a5.208 5.208 0 0 1-5.1-5.2 5.121 5.121 0 0 1 5.1-5.1h7.5v-20.9a5.15 5.15 0 0 1 10.3 0v20.9h7.4a5.168 5.168 0 0 1 5.3 5.1z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M706.8 237.83h2.6l15.8 20.1v-20.1h2.8v25h-2.3l-16.2-20.5v20.5h-2.8v-25z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M735.1 237.83h18.1v2.6h-15.3v8.5h13.7v2.6h-13.7v8.8h15.5v2.6h-18.3z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M769.8 237.83h2.8v22.4h14.1v2.6h-16.9v-25z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M791.5 237.33h3.1v3h-3.1zm.2 7.1h2.8v18.5h-2.8z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M800.9 244.43h2.8v3.1a6.839 6.839 0 0 1 6-3.5 6.204 6.204 0 0 1 5.9 3.7 7.438 7.438 0 0 1 6.5-3.7c4.3 0 6.9 2.9 6.9 7.4v11.4h-2.8v-10.8c0-3.6-1.8-5.6-4.8-5.6-2.8 0-5.1 2.1-5.1 5.7v10.7h-2.8v-10.8c0-3.5-1.8-5.5-4.8-5.5s-5.2 2.4-5.2 5.8v10.6h-2.8v-18.5z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M835 237.33h3.1v3H835zm.2 7.1h2.8v18.5h-2.8z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M845.3 258.03v-11.2h-2.6v-2.4h2.6v-5.6h2.8v5.6h5.9v2.4h-6v10.8c0 2.3 1.3 3.1 3.1 3.1a6.967 6.967 0 0 0 2.7-.6v2.4a7 7 0 0 1-3.3.8c-3-.1-5.2-1.6-5.2-5.3z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M857.1 253.73c0-5.4 3.8-9.7 8.9-9.7 5.5 0 8.6 4.4 8.6 9.8v.9h-14.7c.4 4 3.2 6.3 6.5 6.3a7.55 7.55 0 0 0 5.8-2.6l1.7 1.5a9.518 9.518 0 0 1-7.6 3.5 9.298 9.298 0 0 1-9.2-9.7zm14.7-1.1c-.3-3.4-2.2-6.3-5.9-6.3-3.2 0-5.7 2.7-6 6.3z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                    <path
                      d="M878.3 253.73c0-6.1 4.4-9.7 8.8-9.7a8.525 8.525 0 0 1 7.1 3.9v-11.2h2.8v26.1h-2.8v-3.7a8.366 8.366 0 0 1-7.1 4.1c-4.4.1-8.8-3.4-8.8-9.5zm16.1 0a6.95 6.95 0 0 0-6.7-7.2c-3.6 0-6.5 2.6-6.5 7.1v.1c0 4.4 3 7.2 6.5 7.2a7.083 7.083 0 0 0 6.7-7.2z"
                      fill="#ffffff"
                      data-color="1"
                    ></path>
                  </g>
                </g>
              </svg> */}
              <div style={{ textAlign: "right" }}>
                <img
                  style={{ maxWidth: "250px" }}
                  src="assets/images/logos/marigold-insulation.png"
                  alt=""
                />
              </div>
              {/* <div >
                <img src="assets/images/nea.webp" alt="" />
              </div> */}
            </div>
            <div class="col-lg-2 col-md-3 col-sm-3 col-xs-12 d-md-block d-none second_padding_bottom">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/eco-4">Eco 4 Funding</a>
                </li>
                <li>
                  <a href="/air-source-heating"> Air Source Heat Pumps</a>
                </li>
                <li>
                  <a href="/external-wall">External Wall Insulation</a>
                </li>
                <li>
                  <a href="/solar-energy">Solar PV</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-3 col-xs-12 d-lg-block d-none second_padding_bottom">
              <ul>
                <li>
                  <a href="/about-us">About Us</a>
                </li>
                <li>
                  <a href="/faqs">FAQs</a>
                </li>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>
                {/* <li>
                  <a href="#">Privacy Statement</a>
                </li>
                <li>
                  <a href="#">Terms and Conditions</a>
                </li> */}
              </ul>
            </div>
            <div class="col-lg-5 footer-lasr">
              <p class="ffont_17">
                <span style={{ fontWeight: "bold" }}>
                  <span>
                    Call:{" "}
                    <span>
                      <a href="tel:01384985974" style={{ color: "#fff" }}>
                        0138 498 5974
                      </a>
                    </span>{" "}
                    to check your eligibility
                  </span>
                </span>
              </p>
              <p class="font_17">
                <span>
                  <span class="color_12">
                    {" "}
                    For General Inquiries:{" "}
                    <a href="mailto:info@marigoldinsulation.uk">
                      info@marigoldinsulation.uk
                    </a>
                  </span>
                </span>
              </p>
              <p class="font_17">
                <span>
                  <span class="color_12">
                    {" "}
                    For Complaints:{" "}
                    <a href="mailto:complaints@marigoldinsulation.uk">
                      complaints@marigoldinsulation.uk
                    </a>
                  </span>
                </span>
              </p>
              <p class="font_17">
                <span>
                  <span class="color_11">
                    {" "}
                    Birmingham Office: Unit 8,Vernon Road,Halesowen,B62 8HN
                  </span>
                </span>
              </p>
              {/* <p class="font_17">
                <span>
                  <span class="color_11">
                    {" "}
                    Luton Office: 52 Overstone road,Luton,LU4 8QZ
                  </span>
                </span>
              </p> */}
              <p class="font_17">
                <span>
                  <span class="color_11">Company Registration: 09566825</span>
                </span>
              </p>
              <div
                style={{ gap: 10 }}
                className="d-flex w-100 flex-row align-items-center"
              >
                <SocialIcon url="https://www.facebook.com/profile.php?id=100086284784266" />
                <SocialIcon url="https://www.instagram.com/marigold_insulation_uk/" />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
