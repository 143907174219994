import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";

function SolidWallInsulation(props) {
  useDocumentTitle("Solid Wall Insulation Grants");
  return (
    <div>
      <div class="header-bg">
        <div class="navbar-section-colidbf_mv2">
          <Header />
          <section class="banner-section">
            <div class="container">
              <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12">
                  <h1 class="header-txt" data-aos="fade-up">
                    Solid Wall Insulation Grants
                  </h1>
                  <div class="qabout-faq">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="qaboutbtn">
                          <a href="/faqs" class="qabout-btn">
                            FAQs
                          </a>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <h4>
                          Got a question first?
                          <br />
                          Visit out FAQs page
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12  mt-3">
                  <div class="qa-faq" data-aos="fade-down">
                    <h2 class="fdaont_2">
                      How efficient is
                      <br />{" "}
                      <span>
                        <span class="green-light">YOUR</span>{" "}
                      </span>
                      home?
                    </h2>
                    <div class="company_btn_wrapper">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="text-decoration-none learn_btn_company_stats"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                      >
                        CHECK ELIGIBILITY
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section class="step-sec-about mt-3">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="step-box">
                <h2 class="green-dark">THREE SIMPLE STEPS:</h2>
                <div class="row">
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon1.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Take 60 seconds of your <br />
                        time to fill out the form
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon2.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        We’ll let you know whether <br />
                        you’re eligible for a grant
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon3.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Our engineers will be in touch to <br />
                        book a survey and installation date
                      </h5>
                    </div>
                  </div>
                </div>
                <span>
                  No more days and nights spent battling the cold. Keep your
                  home nice and cosy and keep those energy bills low.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="assets/images/solid_mv2.webp" width="100%" alt="" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  With the help of Marigold Insulation Ltd, you may solve the
                  problem of uninsulated walls and reduce your energy costs.
                  According to estimates, 36% of the millions of homes in the UK
                  have non-cavity solid walls, which often lead to poor energy
                  efficiency, particularly during the colder months.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Our fully financed solid wall insulation can increase the
                  comfort of your home by significantly lowering heat loss and
                  energy costs while using certified systems. If you are a low
                  income family or a vulnerable home, we recognise that solid
                  wall insulation might be tough to pay, but our committed team
                  can assist eligible applications.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Your home can have solid wall insulation installed around the
                  exterior-facing walls and finished with a fully plastered
                  surface to make it warm, insulated, and ready for your own
                  interior design. Our knowledgeable team of advisors will walk
                  you through the procedure and make sure our service is
                  customised to your needs.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Do you want to apply for a grant for solid wall insulation?
                  Simply fill out the short online form below to discover if you
                  might be qualified, and one of our helpful energy consultants
                  will get in touch with you shortly. Remember to look at our
                  FAQ page for more details.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Do not fear; all is not lost if you do not match the
                  requirements. We can get in touch with your local government
                  to see if they can provide our services through flexible
                  eligibility.
                </p>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  <strong>
                    We can also assist with financially supported renovations to
                    high heat retention electric storage heaters and insulation
                    for homes heated by electricity.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="red-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>Fill in the form to check your eligibility.</h5>
            </div>
          </div>
        </div>
      </section>
      {/* <CarouselComponent /> */}
    </div>
  );
}

export default SolidWallInsulation;
