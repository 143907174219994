import React from "react";
import { useState } from "react";
import Header from "../Components/Header";
import axios from "axios";
import Select from "react-select";
import CarouselComponent from "../Components/CarouselComponent";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BASE_URL, token, carouselConfig } from "../Util/config";
import { toast, ToastContainer } from "react-toastify";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";

function HomePage(props) {
  useDocumentTitle("Home");
  let [postcode, setPostcode] = useState("");
  let [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  let [showRating, setShowRating] = useState(false);
  let handleRatingColor = (rating) => {
    let ratingColors = {
      A: "#008054",
      B: "#19b359",
      C: "#8dce46",
      D: "#ffd500",
      E: "#fcaa65",
      F: "#ef8023",
      G: "#e9153b",
    };
    let result = ratingColors[rating] || "#008054";
    return result;
  };

  let fetchData = async () => {
    try {
      if (!postcode) {
        toast("Please enter a post code first");
      } else {
        let webApiUrl = `${BASE_URL}?postcode=${postcode}`;
        let response = await axios.get(webApiUrl, {
          headers: {
            Authorization: `Basic ${token}`,
            Accept: "application/json",
          },
        });
        let _data =
          response?.data?.rows?.map((item, index) => ({
            ...item,
            label: item?.address,
            value: index + 1,
          })) || [];
        let result = _data.reduce((unique, o) => {
          if (!unique.some((obj) => obj.address === o.address)) {
            unique.push(o);
          }
          return unique;
        }, []);
        setData(result);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <div class="header-bg">
        <div class="navbar-section">
          <Header />
          <Carousel
            autoPlay={false}
            infinite
            arrows={false}
            autoPlaySpeed={5000}
            responsive={carouselConfig}
          >
            <section class="banner-section">
              <div class="container">
                <ToastContainer />
                <div class="row">
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <h1 class="header-txt" data-aos="fade-up">
                      100% FREE INSTALLATION OF BUILDING INSULATION AND HEATING
                      SYSTEMS THROUGH ECO4 SCHEME
                    </h1>
                    <p class="listheader" data-aos="fade-right">
                      If your home is heated either with Electric room / Storage
                      heaters, Warm air system, Gas room heaters – or you have
                      no heating system at all, you can obtain funding towards
                      something called First Time Central Heating (FTCH). There
                      are other older heating systems that qualifies too, please
                      check below for the full list. We will install a new Gas
                      Combi Boiler with radiators in different rooms of the
                      property. In order to qualify, your property must not have
                      had Gas Central Heating or any radiators in the past and
                      your existing heating system must be either old and
                      inefficient or broken.
                    </p>
                    {/* <p class="listheader" data-aos="fade-right">
                    <svg
                      preserveAspectRatio="xMidYMid meet"
                      data-bbox="36 47 128 106"
                      viewBox="36 47 128 106"
                      height="200"
                      width="200"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="color"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <defs>
                        <style>
                          {`#comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                        </style>
                      </defs>
                      <g>
                        <path
                          d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                          fill="#2F54DD"
                          data-color="1"
                        ></path>
                      </g>
                    </svg>
                    &nbsp;<span>Receive one or more state benefits?</span>
                  </p>
                  <p class="listheader" data-aos="fade-right">
                    <svg
                      preserveAspectRatio="xMidYMid meet"
                      data-bbox="36 47 128 106"
                      viewBox="36 47 128 106"
                      height="200"
                      width="200"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="color"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <defs>
                        <style>
                          {`#comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                        </style>
                      </defs>
                      <g>
                        <path
                          d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                          fill="#2F54DD"
                          data-color="1"
                        ></path>
                      </g>
                    </svg>
                    &nbsp;<span>100% free replacement</span>
                  </p>
                  <p class="listheader" data-aos="fade-right">
                    <svg
                      preserveAspectRatio="xMidYMid meet"
                      data-bbox="36 47 128 106"
                      viewBox="36 47 128 106"
                      height="200"
                      width="200"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="color"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <defs>
                        <style>
                          {`#comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                        </style>
                      </defs>
                      <g>
                        <path
                          d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                          fill="#2F54DD"
                          data-color="1"
                        ></path>
                      </g>
                    </svg>
                    &nbsp;<span>100% official and government funded</span>
                  </p>
                  <p class="listheader" data-aos="fade-right">
                    <svg
                      preserveAspectRatio="xMidYMid meet"
                      data-bbox="36 47 128 106"
                      viewBox="36 47 128 106"
                      height="200"
                      width="200"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="color"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <defs>
                        <style>
                          {`#comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                        </style>
                      </defs>
                      <g>
                        <path
                          d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                          fill="#2F54DD"
                          data-color="1"
                        ></path>
                      </g>
                    </svg>
                    &nbsp;
                    <span>
                      It’s easy and super-fast to apply (no lengthy forms or
                      endless questionnaires to fill in)
                    </span>
                  </p>
                  <p class="listheader" data-aos="fade-right">
                    <svg
                      preserveAspectRatio="xMidYMid meet"
                      data-bbox="36 47 128 106"
                      viewBox="36 47 128 106"
                      height="200"
                      width="200"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="color"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <defs>
                        <style>
                          {`#comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                        </style>
                      </defs>
                      <g>
                        <path
                          d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                          fill="#2F54DD"
                          data-color="1"
                        ></path>
                      </g>
                    </svg>
                    &nbsp;
                    <span>
                      Free cavity wall and loft insulation also installed
                    </span>
                  </p>
                  <p class="listheader" data-aos="fade-right">
                    <svg
                      preserveAspectRatio="xMidYMid meet"
                      data-bbox="36 47 128 106"
                      viewBox="36 47 128 106"
                      height="200"
                      width="200"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="color"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <defs>
                        <style>
                          {`#comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                        </style>
                      </defs>
                      <g>
                        <path
                          d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                          fill="#2F54DD"
                          data-color="1"
                        ></path>
                      </g>
                    </svg>
                    &nbsp;
                    <span>
                      We do all the hard work for you: applying on your behalf,
                      installing your energy solutions
                    </span>
                  </p> */}
                    <div data-aos="fade-right" class="text-box">
                      <h3 class="font_31">
                        <span>
                          <span class="green-dark">
                            Under an official, Government <br />
                            backed scheme, you can get
                          </span>
                          <span class="green-light">
                            FREE <br />
                            home energy improvements!
                          </span>
                        </span>
                      </h3>
                      <div class="arrowimg">
                        <svg
                          preserveAspectRatio="none"
                          data-bbox="5.501 43.979 184.147 119.845"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="5.501 43.979 184.147 119.845"
                          role="presentation"
                          aria-hidden="true"
                          aria-labelledby="svgcid-ktxirx97ko17"
                        >
                          <title id="svgcid-ktxirx97ko17"></title>
                          <g>
                            <path d="M186.8 125.5c-1.8 8.3-10.9 12.4-18.5 10.6-8.2-1.9-11.3-10.8-10.5-18.7.5-5 .9-8 1.9-13.5C143 125.7 113 144.1 92.8 152c-30.6 12-71.7 14.4-80.5 9.3-6.5-3.8-11.9-18.5 1.4-18.2 19.5.3 45.1-4.5 63.1-11.2 15-5.6 45.8-25.3 58.4-44.7-1.4.7-13.7 8.3-17.4 10.2-4.9 2.5-15 .2-18.9-6.5-4.3-7.4 1.1-17.7 4.2-20.4 8.7-7.6 20.2-11.2 30.7-15.7 9.6-4 19.3-7.7 29.3-10.3 8.4-2.2 15.7 2.8 18.5 10.6 8.2 22.8 10.5 46.5 5.2 70.4z"></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="postcode-box" data-aos="fade-down">
                      <h2 class="font_21">
                        <span>
                          <span class="green-dark">
                            Find out if you are eligible for&nbsp;
                          </span>
                          <span class="green-light">FREE</span>
                          <span class="green-dark">
                            {" "}
                            electric heating system replacement
                          </span>
                        </span>
                      </h2>
                      <p>
                        <strong>
                          {data?.length <= 0
                            ? "Simply enter your postcode to find out..."
                            : showRating === false
                            ? "Select Your Address"
                            : showRating === true
                            ? "Your property is currently rated"
                            : ""}
                        </strong>
                      </p>

                      <div class="postfield">
                        {showRating === false ? (
                          selectedOption === null && data?.length <= 0 ? (
                            <input
                              type="text"
                              name="your-postcode"
                              id="input_comp-l148h3ya3"
                              class="post-field"
                              value={postcode}
                              onChange={(e) => setPostcode(e.target.value)}
                              placeholder="Your Postcode"
                              required=""
                              aria-required="true"
                              aria-label="Your Postcode"
                              aria-invalid="false"
                            />
                          ) : (
                            <Select
                              onChange={(value) => {
                                setSelectedOption(value);
                              }}
                              options={data}
                            />
                          )
                        ) : (
                          <>
                            <div class="">
                              {/* <h5 class="font_25">
                              <span>
                                <span class="green-dark">
                                  Your property is currently rated
                                </span>
                              </span>
                            </h5> */}
                              <div
                                style={{
                                  backgroundColor: handleRatingColor(
                                    selectedOption["current-energy-rating"] ||
                                      "A"
                                  ),
                                }}
                                class="yellowrating"
                              >
                                <p>Energy rating</p>
                                <h2>
                                  {" "}
                                  {selectedOption["current-energy-rating"] ||
                                    "A"}
                                </h2>
                              </div>
                              <p>
                                <strong>
                                  The lower your home's energy efficiency
                                  rating, the more money the government make
                                  available for you to improve it.
                                </strong>
                              </p>
                              <a
                                href="https://marigoldinsulation.uk/eco-4"
                                target="_parent"
                              >
                                <button
                                  type="button"
                                  class="post-btn"
                                  data-testid="buttonContent"
                                  aria-label="Find my address"
                                >
                                  Find out how
                                </button>
                              </a>
                            </div>
                            {/* <p>
                            Your Overall rating is{" "}
                            <b>
                              {selectedOption["current-energy-rating"] || "A"}
                            </b>
                          </p> */}
                          </>
                        )}

                        <br />
                        {data?.length <= 0 ? (
                          <button
                            onClick={() => {
                              fetchData();
                            }}
                            type="button"
                            class="post-btn"
                            data-testid="buttonContent"
                            aria-label="Find my address"
                          >
                            Find my address
                          </button>
                        ) : showRating === false ? (
                          <button
                            onClick={() => {
                              setShowRating((prevState) => !prevState);
                            }}
                            type="button"
                            class="post-btn"
                            disabled={selectedOption === null}
                            data-testid="buttonContent"
                            aria-label=" Reveal My Rating"
                          >
                            Reveal My Rating
                          </button>
                        ) : null}
                      </div>
                    </div>

                    <div>
                      <div
                        data-mesh-id="comp-l11xckcqinlineContent"
                        data-testid="inline-content"
                        class=""
                      >
                        <div
                          data-mesh-id="comp-l11xckcqinlineContent-gridContainer"
                          data-testid="mesh-container-content"
                        >
                          <div
                            id="comp-l11xckcz"
                            class="_1KV2M"
                            data-angle="0"
                            data-aos="fade-right"
                            data-angle-style-location="style"
                            style={{ visibility: "inherit" }}
                            data-screen-in-hide="done"
                          >
                            <div
                              data-mesh-id="comp-l11xckczinlineContent"
                              data-testid="inline-content"
                              class=""
                            >
                              <div
                                data-mesh-id="comp-l11xckczinlineContent-gridContainer"
                                data-testid="mesh-container-content"
                              >
                                <div data-mesh-id="comp-l11xckd3-rotated-wrapper">
                                  <div id="comp-l11xckd3" class="">
                                    <div
                                      data-testid="svgRoot-comp-l11xckd3"
                                      class="_3bLYT _2OIRR"
                                    >
                                      <svg
                                        preserveAspectRatio="none"
                                        data-bbox="54.318 32.147 269.195 314.708"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="54.318 32.147 269.195 314.708"
                                        role="presentation"
                                        aria-hidden="true"
                                      >
                                        <g>
                                          <path d="M54.318 97.212L186.5 32.147l137.013 65.065v249.643H54.318V97.212z"></path>
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="comp-l11xckda"
                                  class="_1Q9if _3bcaz"
                                  data-testid="richTextElement"
                                >
                                  <p class="font_8">
                                    <span>
                                      <span>A</span>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            id="comp-l22xckcz"
                            class="_1KV2M"
                            data-angle="0"
                            data-aos="fade-right"
                            data-angle-style-location="style"
                            style={{ visibility: "inherit" }}
                            data-screen-in-hide="done"
                          >
                            <div
                              data-mesh-id="comp-l22xckczinlineContent"
                              data-testid="inline-content"
                              class=""
                            >
                              <div
                                data-mesh-id="comp-l22xckczinlineContent-gridContainer"
                                data-testid="mesh-container-content"
                              >
                                <div data-mesh-id="comp-l22xckd3-rotated-wrapper">
                                  <div id="comp-l22xckd3" class="">
                                    <div
                                      data-testid="svgRoot-comp-l22xckd3"
                                      class="_3bLYT _2OIRR"
                                    >
                                      <svg
                                        preserveAspectRatio="none"
                                        data-bbox="54.318 32.147 269.195 314.708"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="54.318 32.147 269.195 314.708"
                                        role="presentation"
                                        aria-hidden="true"
                                      >
                                        <g>
                                          <path d="M54.318 97.212L186.5 32.147l137.013 65.065v249.643H54.318V97.212z"></path>
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="comp-l22xckda"
                                  class="_1Q9if _3bcaz"
                                  data-testid="richTextElement"
                                >
                                  <p class="font_8">
                                    <span>
                                      <span>B</span>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            id="comp-l33xckcz"
                            class="_1KV2M"
                            data-angle="0"
                            data-aos="fade-right"
                            data-angle-style-location="style"
                            style={{ visibility: "inherit" }}
                            data-screen-in-hide="done"
                          >
                            <div
                              data-mesh-id="comp-l33xckczinlineContent"
                              data-testid="inline-content"
                              class=""
                            >
                              <div
                                data-mesh-id="comp-l33xckczinlineContent-gridContainer"
                                data-testid="mesh-container-content"
                              >
                                <div data-mesh-id="comp-l33xckd3-rotated-wrapper">
                                  <div id="comp-l33xckd3" class="">
                                    <div
                                      data-testid="svgRoot-comp-l33xckd3"
                                      class="_3bLYT _2OIRR"
                                    >
                                      <svg
                                        preserveAspectRatio="none"
                                        data-bbox="54.318 32.147 269.195 314.708"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="54.318 32.147 269.195 314.708"
                                        role="presentation"
                                        aria-hidden="true"
                                      >
                                        <g>
                                          <path d="M54.318 97.212L186.5 32.147l137.013 65.065v249.643H54.318V97.212z"></path>
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="comp-l33xckda"
                                  class="_1Q9if _3bcaz"
                                  data-testid="richTextElement"
                                >
                                  <p class="font_8">
                                    <span>
                                      <span>C</span>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            id="comp-l44xckcz"
                            class="_1KV2M"
                            data-angle="0"
                            data-aos="fade-right"
                            data-angle-style-location="style"
                            style={{ visibility: "inherit" }}
                            data-screen-in-hide="done"
                          >
                            <div
                              data-mesh-id="comp-l44xckczinlineContent"
                              data-testid="inline-content"
                              class=""
                            >
                              <div
                                data-mesh-id="comp-l44xckczinlineContent-gridContainer"
                                data-testid="mesh-container-content"
                              >
                                <div data-mesh-id="comp-l44xckd3-rotated-wrapper">
                                  <div id="comp-l44xckd3" class="">
                                    <div
                                      data-testid="svgRoot-comp-l44xckd3"
                                      class="_3bLYT _2OIRR"
                                    >
                                      <svg
                                        preserveAspectRatio="none"
                                        data-bbox="54.318 32.147 269.195 314.708"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="54.318 32.147 269.195 314.708"
                                        role="presentation"
                                        aria-hidden="true"
                                      >
                                        <g>
                                          <path d="M54.318 97.212L186.5 32.147l137.013 65.065v249.643H54.318V97.212z"></path>
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="comp-l44xckda"
                                  class="_1Q9if _3bcaz"
                                  data-testid="richTextElement"
                                >
                                  <p class="font_8">
                                    <span>
                                      <span>D</span>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            id="comp-l1e1xckcz"
                            class="_1KV2M"
                            data-angle="0"
                            data-aos="fade-right"
                            data-angle-style-location="style"
                            style={{ visibility: "inherit" }}
                            data-screen-in-hide="done"
                          >
                            <div
                              data-mesh-id="comp-l1e1xckczinlineContent"
                              data-testid="inline-content"
                              class=""
                            >
                              <div
                                data-mesh-id="comp-l1e1xckczinlineContent-gridContainer"
                                data-testid="mesh-container-content"
                              >
                                <div data-mesh-id="comp-l1e1xckd3-rotated-wrapper">
                                  <div id="comp-l1e1xckd3" class="">
                                    <div
                                      data-testid="svgRoot-comp-l1e1xckd3"
                                      class="_3bLYT _2OIRR"
                                    >
                                      <svg
                                        preserveAspectRatio="none"
                                        data-bbox="54.318 32.147 269.195 314.708"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="54.318 32.147 269.195 314.708"
                                        role="presentation"
                                        aria-hidden="true"
                                      >
                                        <g>
                                          <path d="M54.318 97.212L186.5 32.147l137.013 65.065v249.643H54.318V97.212z"></path>
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="comp-l1e1xckda"
                                  class="_1Q9if _3bcaz"
                                  data-testid="richTextElement"
                                >
                                  <p class="font_8">
                                    <span>
                                      <span>E</span>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            id="comp-l2f2xckcz"
                            class="_1KV2M"
                            data-angle="0"
                            data-aos="fade-right"
                            data-angle-style-location="style"
                            style={{ visibility: "inherit" }}
                            data-screen-in-hide="done"
                          >
                            <div
                              data-mesh-id="comp-l2f2xckczinlineContent"
                              data-testid="inline-content"
                              class=""
                            >
                              <div
                                data-mesh-id="comp-l2f2xckczinlineContent-gridContainer"
                                data-testid="mesh-container-content"
                              >
                                <div data-mesh-id="comp-l2f2xckd3-rotated-wrapper">
                                  <div id="comp-l2f2xckd3" class="">
                                    <div
                                      data-testid="svgRoot-comp-l2f2xckd3"
                                      class="_3bLYT _2OIRR"
                                    >
                                      <svg
                                        preserveAspectRatio="none"
                                        data-bbox="54.318 32.147 269.195 314.708"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="54.318 32.147 269.195 314.708"
                                        role="presentation"
                                        aria-hidden="true"
                                      >
                                        <g>
                                          <path d="M54.318 97.212L186.5 32.147l137.013 65.065v249.643H54.318V97.212z"></path>
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="comp-l2f2xckda"
                                  class="_1Q9if _3bcaz"
                                  data-testid="richTextElement"
                                >
                                  <p class="font_8">
                                    <span>
                                      <span>F</span>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            id="comp-l3g3xckcz"
                            class="_1KV2M"
                            data-angle="0"
                            data-aos="fade-right"
                            data-angle-style-location="style"
                            style={{ visibility: "inherit" }}
                            data-screen-in-hide="done"
                          >
                            <div
                              data-mesh-id="comp-l3g3xckczinlineContent"
                              data-testid="inline-content"
                              class=""
                            >
                              <div
                                data-mesh-id="comp-l3g3xckczinlineContent-gridContainer"
                                data-testid="mesh-container-content"
                              >
                                <div data-mesh-id="comp-l3g3xckd3-rotated-wrapper">
                                  <div id="comp-l3g3xckd3" class="">
                                    <div
                                      data-testid="svgRoot-comp-l3g3xckd3"
                                      class="_3bLYT _2OIRR"
                                    >
                                      <svg
                                        preserveAspectRatio="none"
                                        data-bbox="54.318 32.147 269.195 314.708"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="54.318 32.147 269.195 314.708"
                                        role="presentation"
                                        aria-hidden="true"
                                      >
                                        <g>
                                          <path d="M54.318 97.212L186.5 32.147l137.013 65.065v249.643H54.318V97.212z"></path>
                                        </g>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="comp-l3g3xckda"
                                  class="_1Q9if _3bcaz"
                                  data-testid="richTextElement"
                                >
                                  <p class="font_8">
                                    <span>
                                      <span>G</span>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="banner-section">
              <div class="container">
                <div class="row">
                  <div class="col-lg-7 col-md-12 col-sm-12">
                    <h1 class="header-txt" data-aos="fade-up">
                      Grant for free external wall insulation. 100% free to
                      install and fully backed by the UK governmentl
                    </h1>
                    <p class="faont_7">
                      <span class="color_11">
                        In numerous ways, the ECO4 Scheme aids struggling
                        families and homeowners. One of these methods is the
                        free installation of external wall insulation in
                        qualified homes.
                      </span>
                    </p>
                    <p class="faont_7">
                      <span class="color_11">
                        You have a strong possibility of becoming eligible for
                        the grant (and It only takes a minute to find out!)
                      </span>
                    </p>

                    <p class="listheader" data-aos="fade-right">
                      <svg
                        preserveAspectRatio="xMidYMid meet"
                        data-bbox="36 47 128 106"
                        viewBox="36 47 128 106"
                        height="200"
                        width="200"
                        xmlns="http://www.w3.org/2000/svg"
                        data-type="color"
                        role="presentation"
                        aria-hidden="true"
                      >
                        <defs>
                          <style>
                            {`
                                            #comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                          </style>
                        </defs>
                        <g>
                          <path
                            d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                            fill="#2F54DD"
                            data-color="1"
                          ></path>
                        </g>
                      </svg>
                      &nbsp;
                      <span>
                        Homeowner or private tenant (if renting, you will need
                        landlord’s permission)?
                      </span>
                    </p>
                    <br />

                    <p class="listheader" data-aos="fade-right">
                      <svg
                        preserveAspectRatio="xMidYMid meet"
                        data-bbox="36 47 128 106"
                        viewBox="36 47 128 106"
                        height="200"
                        width="200"
                        xmlns="http://www.w3.org/2000/svg"
                        data-type="color"
                        role="presentation"
                        aria-hidden="true"
                      >
                        <defs>
                          <style>
                            {`
                                            #comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                          </style>
                        </defs>
                        <g>
                          <path
                            d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                            fill="#2F54DD"
                            data-color="1"
                          ></path>
                        </g>
                      </svg>
                      &nbsp;<span>Receiving income-based benefits?</span>
                    </p>
                    <br />

                    <p class="listheader" data-aos="fade-right">
                      <svg
                        preserveAspectRatio="xMidYMid meet"
                        data-bbox="36 47 128 106"
                        viewBox="36 47 128 106"
                        height="200"
                        width="200"
                        xmlns="http://www.w3.org/2000/svg"
                        data-type="color"
                        role="presentation"
                        aria-hidden="true"
                      >
                        <defs>
                          <style>
                            {`
                                            #comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                          </style>
                        </defs>
                        <g>
                          <path
                            d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                            fill="#2F54DD"
                            data-color="1"
                          ></path>
                        </g>
                      </svg>
                      &nbsp;<span>Live in a high-carbon emitting home?</span>
                    </p>
                    <br />
                    <p class="listheader" data-aos="fade-right">
                      <svg
                        preserveAspectRatio="xMidYMid meet"
                        data-bbox="36 47 128 106"
                        viewBox="36 47 128 106"
                        height="200"
                        width="200"
                        xmlns="http://www.w3.org/2000/svg"
                        data-type="color"
                        role="presentation"
                        aria-hidden="true"
                      >
                        <defs>
                          <style>
                            {`
                                            #comp-l11xk6rv svg [data-color="1"] {
                                                fill: #FFFFFF;
                                            }`}
                          </style>
                        </defs>
                        <g>
                          <path
                            d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                            fill="#2F54DD"
                            data-color="1"
                          ></path>
                        </g>
                      </svg>
                      &nbsp;
                      <span>Struggling to cope with the cost of living?</span>
                    </p>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12">
                    <div class="qa-faq" data-aos="fade-down">
                      <h2 class="fdaont_2">
                        Applications for free external wall insulation are
                        limited,{" "}
                        <span>
                          <span class="green-light">
                            so take action and apply now
                          </span>
                        </span>
                      </h2>
                      <div class="company_btn_wrapper">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          class="text-decoration-none learn_btn_company_stats"
                          href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                        >
                          CHECK ELIGIBILITY
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="banner-section">
              <div class="container">
                <div class="row">
                  <div class="col-lg-7 col-md-12 col-sm-12">
                    <h1 class="header-txt" data-aos="fade-up">
                      Grants for free Air Source Heat Pumps.
                    </h1>
                    <p class="fontww_7">
                      <span class="color_11">
                        Find out how the newest, most energy-efficient Air
                        Source Heat Pumps could help you save a tonne of money
                        each month Air Source Heat Pumps.{" "}
                        <span style={{ fontWeight: "bold" }}>
                          And to apply and install them is completely free.{" "}
                        </span>
                      </span>
                    </p>
                    <p class="fontww_7">
                      <span class="color_11">
                        Electricity can be a strong and affordable solution to
                        heat your home for less as energy prices continue to
                        rise and gas gets a bad rap for being bad for the
                        environment! You may be eligible to get free
                        installation of brand-new, top-of-the-line Air Source
                        Heat Pumps in your home . All of this is a component of
                        the government's ECO4 programme, which Marigold may
                        implement for you.
                      </span>
                    </p>

                    <p class="fontww_7">
                      <span class="color_11">
                        Find out right now whether you qualify. It's quick,
                        safe, and only needs a few minutes.
                      </span>
                    </p>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12">
                    <div class="qa-faq" data-aos="fade-down">
                      <h2 class="fwont_2" style={{ lineHeight: "36px" }}>
                        100% FREE{" "}
                        <span>
                          <span class="green-dark">
                            to <br />
                            APPLY and <br />
                            INSTALL
                          </span>
                        </span>
                      </h2>
                      <div class="company_btn_wrapper">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          class="text-decoration-none learn_btn_company_stats"
                          href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                        >
                          CHECK ELIGIBILITY
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Carousel>
        </div>
      </div>
      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="qa-faq">
                <div class="row">
                  <div class="col-md-9">
                    <h4 class="green-dark">
                      Got a question first? Visit our FAQs page
                    </h4>
                  </div>
                  <div class="col-md-3">
                    <div class="farbtn">
                      <a href="/faqs" class="faq-btn">
                        FAQs
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="step-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="step-box">
                <h2>THREE SIMPLE STEPS:</h2>
                <div class="row">
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon1.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Take 60 seconds of your <br />
                        time to fill out the form
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon2.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        We’ll let you know whether <br />
                        you’re eligible for a grant
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon3.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Our engineers will be in touch to <br />
                        book a survey and installation date
                      </h5>
                    </div>
                  </div>
                </div>
                <span>
                  No more days and nights spent battling the cold. Keep your
                  home nice and cosy and keep those energy bills low.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="about_us">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="font_3about" data-aos="fade-right">
                  <span>
                    <span class="green-dark">
                      UK families face threats of difficulty{" "}
                    </span>
                    <span class="green-light">paying utility bills</span>
                  </span>
                </h3>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Energy crises are no fun. But sadly, heating costs have become
                  so high that by 2022 her 1,000 households in the UK will
                  either be forced to live in damp and cold conditions or face
                  the financial burden of utility bills. will be
                </p>
                <p class="about_us_paragraph mb-0" data-aos="fade-right">
                  Marigolds cannot stop the energy crisis. But we can help
                  soften the blow to struggling families across the UK.
                </p>
                <h2 class="font_2about" data-aos="fade-right">
                  <span class="green-light">
                    How do I know I’m eligible for the government’s <br />
                    <span class="green-dark">FREE energy scheme?</span>
                  </span>
                </h2>
                <p class="about_us_paragraph mb-0" data-aos="fade-right">
                  Complete this quick and easy claim form to find out if you're
                  eligible today. It takes just a minute and can save you
                  hundreds of pounds in heating costs throughout the year.
                </p>
                <p class="about_us_paragraph mb-0" data-aos="fade-right">
                  The best part is that we do all the hard work for you! We
                  organize the funds for you. In addition, we can arrange
                  installation at your convenience (all done by certified and
                  certified installers for your peace of mind
                </p>
                <div class="company_btn_wrapper">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    class="text-decoration-none learn_btn_company_stats"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                  >
                    CHECK ELIGIBILITY
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img src="assets/images/worried-man.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="freesec">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="free-complete">
                <div class="row">
                  <div class="col-md-5">
                    <div class="fretxt">
                      <h3 class="font_32" data-aos="fade-right">
                        <span>
                          <span class="color_25">
                            Improvements in insulation and electric heating are{" "}
                          </span>
                          <span class="green-light">entirely FREE!</span>
                        </span>
                      </h3>
                      <div class="bor-bot" data-aos="fade-left"></div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="freebox">
                      <p
                        class="listheader aos-init aos-animate"
                        data-aos="fade-right"
                      >
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`
                                                    #comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;
                        <span>FREE electric storage heater replacement</span>
                      </p>
                      <p
                        class="listheader aos-init aos-animate"
                        data-aos="fade-right"
                      >
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {` #comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                   }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;<span>FREE cavity wall insulation</span>
                      </p>
                      <p
                        class="listheader aos-init aos-animate"
                        data-aos="fade-right"
                      >
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`
                                                    #comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;<span>FREE loft insulation</span>
                      </p>
                      <p
                        class="listheader aos-init aos-animate"
                        data-aos="fade-right"
                      >
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`#comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;<span>FREE wall insulation</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", padding: "50px 0" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="font_a3a" data-aos="fade-right">
                  <span>
                    <span class="green-light">Eligibility</span>
                    <span class="color_25">criteria:</span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  You can get a new, energy-efficient electric heaters fitted
                  for <b>FREE</b>, if you receive at least one of the following
                  benefits:
                </p>
                <ul class="font_7">
                  <li>
                    <p class="font_7">
                      Income based Jobseekets allowance (JSA)
                    </p>
                  </li>
                  <li>
                    <p class="font_7">
                      Income related Employment & Support Allowance (ESA)
                    </p>
                  </li>
                  <li>
                    <p class="font_7">Income Support (IS)</p>
                  </li>
                  {/* <li>
                    <p class="font_7">Jobseeker’s Allowance (JSA)</p>
                  </li> */}
                  <li>
                    <p class="font_7">Pension Credit (Guarantee Credit)</p>
                  </li>
                  <li>
                    <p class="font_7">Working Tax Credit (WTC)</p>
                  </li>
                  <li>
                    <p class="font_7">Child Tax Credits (CTC)</p>
                  </li>
                  <li>
                    <p class="font_7">Universal Credit (UC)</p>
                  </li>
                  <li>
                    <p class="font_7">
                      Housing Benefit (new eligible benefit under ECO4)
                    </p>
                  </li>
                  {/* <li>
                    <p class="font_7">Attendance Allowance</p>
                  </li>
                  <li>
                    <p class="font_7">Carer’s Allowance</p>
                  </li>
                  <li>
                    <p class="font_7">Constant Attendance Allowance</p>
                  </li>
                  <li>
                    <p class="font_7">Disability Living Allowance (DLA)</p>
                  </li>
                  <li>
                    <p class="font_7">
                      Industrial Injuries Disablement Benefit (IIDB)
                    </p>
                  </li>
                  <li>
                    <p class="font_7">Personal Independence Payment (PIP)</p>
                  </li>
                  <li>
                    <p class="font_7">Severe Disablement Allowance</p>
                  </li>
                  <li>
                    <p class="font_7">Housing benefit</p>
                  </li> */}
                  <li>
                    <p class="font_7">
                      Pension Credit Savings Credit (new eligible benefit under
                      ECO4)
                    </p>
                  </li>
                  {/* <li>
                    <p class="font_7">Warm Homes Discount</p>
                  </li> */}
                </ul>
                <div class="company_btn_wrapper">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    class="text-decoration-none learn_btn_company_stats"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                  >
                    CHECK ELIGIBILITY
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img src="assets/images/father-son.webp" alt="" />
                </figure>
                <div class="yellow_box center-style" data-aos="flip-up">
                  <h4 class="text-white mb-0">
                    Who can qualify for ECO4 grants?
                  </h4>
                  <p class="text-white mb-0">
                    If you own or rent a property and receive specific
                    income-related benefits, you may qualify for 100% free home
                    energy improvements
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", padding: "50px 0" }}
      >
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_image" data-aos="fade-right">
                <figure class="mb-0">
                  <img src="assets/images/insulation.webp" alt="" />
                </figure>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="font_ab3">
                  <span class="color_25">
                    <span>So what is the Government</span>
                  </span>
                  <span class="green-light">
                    <span style={{ fontSize: "30px" }}> FREE</span>
                  </span>
                  <span class="color_25">
                    <span style={{ fontSize: "30px" }}>
                      {" "}
                      Energy Improvement Program?
                    </span>
                  </span>
                </h3>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  Launched in 2013, the program was set up to provide free
                  heating upgrades to low-income households and households
                  receiving certain government benefits. You may also be
                  eligible for free home improvements, such as insulation, that
                  can help keep your utility bills down and make your home
                  warmer and more efficient.
                </p>
                <div class="company_btn_wrapper">
                  <a
                    class="text-decoration-none learn_btn_company_stats"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                  >
                    APPLY NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="freesec">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="free-complete">
                <div class="row">
                  <div class="col-md-7">
                    <div class="fretxt">
                      <h3
                        class="font_3"
                        style={{
                          fontSize: "30px",
                          color: "#0c3e35",
                          lineHeight: "38px",
                        }}
                      >
                        <span class="color_25">
                          <span style={{ fontSize: "30px" }}>
                            The team at Marigold is
                          </span>
                        </span>
                        <span class="green-light">
                          <span style={{ fontSize: "30px" }}>
                            {" "}
                            certified and qualified
                          </span>
                        </span>
                        <span class="color_25">
                          <span style={{ fontSize: "30px" }}>
                            {" "}
                            to handle all your heating and energy upgrades,
                            including Air Source Heat Pumps, Solar PV, electric
                            and gas boilers
                          </span>
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="freebox">
                      <p
                        class="listheader aos-init aos-animate"
                        data-aos="fade-right"
                      >
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`
                                                    #comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;<span>Gas Safe accredited</span>
                      </p>
                      <p
                        class="listheader aos-init aos-animate"
                        data-aos="fade-right"
                      >
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`
                                                    #comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;<span>Green Deal Approved</span>
                      </p>
                      <p
                        class="listheader aos-init aos-animate"
                        data-aos="fade-right"
                      >
                        <svg
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="36 47 128 106"
                          viewBox="36 47 128 106"
                          height="200"
                          width="200"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                        >
                          <defs>
                            <style>
                              {`
                                                    #comp-l11xk6rv svg [data-color="1"] {
                                                        fill: #99CA3C;
                                                    }`}
                            </style>
                          </defs>
                          <g>
                            <path
                              d="M136.701 58.951l15.34 15.323-66.836 66.777-37.247-37.207 15.34-15.328L85.205 110.4l51.496-51.449zm0-11.951L85.204 98.454 63.297 76.569 36 103.846 85.205 153 164 74.274 136.701 47z"
                              fill="#2F54DD"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        &nbsp;
                        <span>
                          Members of the Cavity Insulation Guarantee Agency
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#0c3e35", padding: "50px 0" }}>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-8">
              <h3 class="fosnt_3">
                <span>
                  <a
                    target="_self"
                    data-anchor="dataItem-l0s6hgdy"
                    style={{ color: "#fff" }}
                  >
                    APPLY FOR YOUR FREE GOVERNMENT ENERGY GRANT NOW.
                  </a>
                </span>
              </h3>
              <h3 class="fonat_3">
                <span>IT TAKES 60 SECONDS AND WE’LL TAKE CARE OF THE REST</span>
                <span style={{ fontSize: "36px" }}></span>
              </h3>
            </div>
            <div class="col-md-1 applysec">
              <svg
                preserveAspectRatio="none"
                data-bbox="5.501 43.979 184.147 119.845"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="5.501 43.979 184.147 119.845"
                role="presentation"
                aria-hidden="true"
                aria-labelledby="svgcid--hfe7so-m48yys"
              >
                <title id="svgcid--hfe7so-m48yys"></title>
                <g>
                  <path d="M186.8 125.5c-1.8 8.3-10.9 12.4-18.5 10.6-8.2-1.9-11.3-10.8-10.5-18.7.5-5 .9-8 1.9-13.5C143 125.7 113 144.1 92.8 152c-30.6 12-71.7 14.4-80.5 9.3-6.5-3.8-11.9-18.5 1.4-18.2 19.5.3 45.1-4.5 63.1-11.2 15-5.6 45.8-25.3 58.4-44.7-1.4.7-13.7 8.3-17.4 10.2-4.9 2.5-15 .2-18.9-6.5-4.3-7.4 1.1-17.7 4.2-20.4 8.7-7.6 20.2-11.2 30.7-15.7 9.6-4 19.3-7.7 29.3-10.3 8.4-2.2 15.7 2.8 18.5 10.6 8.2 22.8 10.5 46.5 5.2 70.4z"></path>
                </g>
              </svg>
            </div>
            <div class="col-md-3">
              <div class="applybtn">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                >
                  APPLY TODAY
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <CarouselComponent /> */}
      {/* <section class="our_testimonials center-style float-left w-100">
        <div class="titleprof">
          <div class="container">
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                <h2>Professional Accreditations</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-2">
              <div class="test-left">
                <img src="assets/images/green.webp" alt="" />
                <p>Reg. No NAPI51657</p>
                <p class="texta">
                  *Cavity wall insulation including that installed in party
                  walls, floor insulation, loft insulation (roll insulation &
                  blown insulation), room in roof insulation, condensing
                  boilers, natural gas fired and liquefied petroleum gas fired,
                  heating controls, electric storage heaters
                </p>
              </div>
            </div>
            <div class="col-md-10">
              <div class="row position-relative">
                <div class="carousel-content" data-aos="fade-up">
                  <div class="carousel-outer">
                    <div class="owl-carousel owl-theme">
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/1.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/2.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/3.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/4.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/5.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/6.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/7.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/8.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/9.webp" alt="" />
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonials_content">
                          <img src="assets/images/logos/10.webp" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default HomePage;
