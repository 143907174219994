import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";

function AirSource(props) {
  useDocumentTitle("Air Source Heat Pump");
  return (
    <div>
      <div class="header-bg">
        <div class="navbar-section-aboutinner">
          <Header />
          <section class="banner-section ">
            <div class="container mt-4">
              <div class="row">
                <div class="col-lg-7 col-md-12 col-sm-12">
                  <h1 class="header-txt" data-aos="fade-up">
                    Do I Qualify for a Government Air Source Heat Pump Grant?{" "}
                  </h1>
                  <div class="qabout-faq">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="qaboutbtn">
                          <a href="/faqs" class="qabout-btn">
                            FAQs
                          </a>
                        </div>
                      </div>
                      <div class="col-md-9 ">
                        <h4>
                          Got a question first?
                          <br />
                          Visit out FAQs page
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-3">
                  <div class="qa-faq " data-aos="fade-down">
                    <h2 class="fdaont_2">
                      How efficient is
                      <br />{" "}
                      <span>
                        <span class="green-light">YOUR</span>
                      </span>{" "}
                      home?
                    </h2>
                    <div class="company_btn_wrapper ">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        class="text-decoration-none learn_btn_company_stats"
                        href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                      >
                        CHECK ELIGIBILITY
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section class="step-sec-about mt-3">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="step-box">
                <h2 class="green-dark">THREE SIMPLE STEPS:</h2>
                <div class="row">
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon1.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Take 60 seconds of your <br />
                        time to fill out the form
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon2.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        We’ll let you know whether <br />
                        you’re eligible for a grant
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="stepbox">
                      <img
                        src="assets/images/stepicon3.png"
                        width="150"
                        alt=""
                      />
                      <h5>
                        Our engineers will be in touch to <br />
                        book a survey and installation date
                      </h5>
                    </div>
                  </div>
                </div>
                <span>
                  No more days and nights spent battling the cold. Keep your
                  home nice and cosy and keep those energy bills low.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="about_us"
        style={{ backgroundColor: "#fff", paddingBottom: 0 }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img
                src="assets/images/air-source-heat-pump.webp"
                width="100%"
                alt=""
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="aboutus_content">
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>How do they </span>
                    <span class="green-light">work?</span>
                  </span>
                </h3>

                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  An air source heat pump is like a reverse refrigerator since
                  it brings heat energy from the outside into your home. Since
                  they generate a lot more heat than the electricity required to
                  operate them, they are incredibly efficient. Due to their
                  simpler design than a boiler, they frequently have lower
                  operating costs than a traditional heating system.
                </p>
                <h3 class="fsont__3" data-aos="fade-right">
                  <span>
                    <span>The advantages of </span>
                    <span class="green-light">Air Source Heat Pumps</span>
                  </span>
                </h3>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  The following are a few benefits of installing heating pumps:{" "}
                </p>
                <ul class="font_7">
                  <li>
                    <p class="font_7">Reduced fuel bills</p>
                  </li>
                  <li>
                    <p class="font_7">Lower carbon emissions</p>
                  </li>
                  <li>
                    <p class="font_7">Hot water included</p>
                  </li>
                  <li>
                    <p class="font_7">Low maintenance costs</p>
                  </li>
                  <li>
                    <p class="font_7">Modern controls</p>
                  </li>
                  <li>
                    <p class="font_7">Easy installation and maintenance</p>
                  </li>
                </ul>
                <p class="about_us_paragraph mb-2" data-aos="fade-right">
                  When you submit your information to us, one of our advisers
                  will go through all the alternatives available to you based on
                  your property and circumstances. It is entirely optional for
                  you to take part in this process, which is completely free.
                  Our straightforward objective is to make your house more
                  energy-efficient without charging you anything.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="red-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5>Fill in the form to check your eligibility.</h5>
            </div>
          </div>
        </div>
      </section>
      {/* <CarouselComponent /> */}
    </div>
  );
}

export default AirSource;
